import { ComponentSdksLoader, CoreSdkLoaders } from '../types'
import { ControllersExports, InitArgs } from './types' // TODO move all core types to ./types
import ClientSpecMapApi from './clientSpecMapService'
import AppsUrlApi from './appsUrlService'
import SessionServiceFactory from './sessionService'
import WixSelector from './wixSelector'
import WixCodeViewerAppUtils from './wixCodeViewerAppUtils'
import { Applications } from './applications'
import modelBuilderFactory from './model'
import { createWixCodeApiFactory } from './createWixCodeSdk'
import { createLinkUtils, createPromise, getDecodedUrlObject, logSdkError, logSdkWarning } from '@wix/thunderbolt-commons'
import createSdkFactoryParams from './createSdkFactoryParams'
import setPropsFactory from './setPropsFactory'
import { ControllerEvents } from './ControllerEvents'
import { DocumentSdkFactory } from './componentsSDK/Document'
import _ from 'lodash'
import { createPlatformApi } from './appsAPI/platformAPI'
import CommonConfigManager from './commonConfigModule'
import BsiManagerModule from './bsiManagerModule'
import { PlatformLogger } from '@wix/thunderbolt-symbols'
import { createWixCodeNamespacesRegistry } from './WixCodeNamespacesRegistry'
import { platformBiLoggerFactory } from './bi/biLoggerFactory'
import { instanceCacheFactory } from './instanceCache'
import { componentSdkStateFactory } from './componentSdkState'
import { ComponentSdksManagerFactory } from './componentSdksManager'
import { RegisterEventFactory } from './createRegisterEvent'
import { PlatformAnimationsAPI } from '../animations'
import { CreateStaticEventsManager } from './staticEventsManager'
import { ModuleLoader } from './loadModules'
import { AppsPublicApiManagerFactory } from './appsPublicApiManager'
import { BuildPlatformUtils } from './buildPlatformUtils'

const createProxy = (handle: Function) => new Proxy({}, { get: (__, prop) => handle(prop) })
type PlatformState = {
	loadComponentSdksPromise: Promise<ComponentSdksLoader>
}

export function createPlatformAPI() {
	const { promise: waitForInit, resolver: initDone } = createPromise<PlatformState>()
	return {
		initPlatformOnSite({ logger, moduleLoader, componentSdksUrl }: { logger: PlatformLogger; moduleLoader: ModuleLoader; componentSdksUrl: string }) {
			// TODO PLAT-1018 this is a fake promise. AMDLoader importScripts modules.
			const loadComponentSdksPromise = moduleLoader.AMDLoader<ComponentSdksLoader>(componentSdksUrl, 'componentSdks').catch((e) => {
				logger.captureError(new Error('could not load component SDKs loader'), {
					groupErrorsBy: 'values',
					tags: { method: 'componentSdksLoader' },
					extra: { componentSdksUrl, error: e }
				})
				return {}
			})
			initDone({
				loadComponentSdksPromise
			})
		},

		async runPlatformOnPage({ bootstrapData, logger, importScripts, siteAssetsClient, moduleLoader, viewerAPI }: InitArgs) {
			logger.interactionStarted('initialisation')
			const platformState = await waitForInit
			const platformEnvData = bootstrapData.platformEnvData
			platformEnvData.url = getDecodedUrlObject(platformEnvData.location.rawUrl)
			const loadComponentSdksPromise = platformState.loadComponentSdksPromise
			const createSdkHandlers = (pageId: string) => createProxy((handlerName: string) => (...args: any) => viewerAPI.invokeSdkHandler(pageId, handlerName, ...args))
			const handlers = createSdkHandlers(bootstrapData.currentPageId) as any
			const modelBuilder = modelBuilderFactory({ bootstrapData, logger, siteAssetsClient, handlers })
			const modelsApi = await logger.runAsyncAndReport('getAllModels', modelBuilder.getModelApi)
			if (_.isEmpty(modelsApi.getApplications())) {
				return
			}
			const componentSdksManager = ComponentSdksManagerFactory({ loadComponentSdksPromise, modelsApi, logger })
			const clientSpecMapApi = ClientSpecMapApi({ bootstrapData })
			const appsPublicApiManager = AppsPublicApiManagerFactory({ modelsApi, clientSpecMapApi, logger, handlers, bootstrapData, importScripts })
			const sdkInstancesCache = instanceCacheFactory()
			const getCompRefById = (compId: string) => createProxy((functionName: string) => (...args: any) => handlers.invokeCompRefFunction(compId, functionName, args))
			const appsUrlApi = AppsUrlApi({ bootstrapData })
			const sessionService = SessionServiceFactory({ bootstrapData, handlers })
			const controllerEventsFactory = ControllerEvents()
			const componentSdkState = componentSdkStateFactory()
			const commonConfigManager = CommonConfigManager(bootstrapData, createSdkHandlers)
			const bsiManager = BsiManagerModule(commonConfigManager, bootstrapData, createSdkHandlers)
			const linkUtils = createLinkUtils({
				isMobileView: bootstrapData.isMobileView,
				getCompIdByWixCodeNickname: modelsApi.getCompIdByWixCodeNickname,
				getRoleForCompId: modelsApi.getRoleForCompId,
				routingInfo: bootstrapData.platformEnvData.router.routingInfo,
				metaSiteId: bootstrapData.platformServicesAPIData.link.metaSiteId,
				userFileDomainUrl: bootstrapData.platformServicesAPIData.link.userFileDomainUrl,
				isPremiumDomain: bootstrapData.platformServicesAPIData.link.isPremiumDomain,
				routersConfig: bootstrapData.platformAPIData.routersConfigMap,
				popupPages: bootstrapData.platformServicesAPIData.link.popupPages
			})
			const wixCodeNamespacesRegistry = createWixCodeNamespacesRegistry()
			const biUtils = platformBiLoggerFactory({ sessionService })
			const platformUtils = BuildPlatformUtils({
				linkUtils,
				sessionService,
				appsPublicApiManager,
				wixCodeNamespacesRegistry,
				biUtils
			})
			const { createSetProps, waitForUpdatePropsPromises, createSetPropsForOOI } = setPropsFactory({ modelsApi, viewerAPI, logger, handlers })
			const registerEventFactory = RegisterEventFactory({ handlers, modelsApi })
			const animationsApi = PlatformAnimationsAPI({ handlers, platformEnvData })
			const { getSdkFactoryParams } = createSdkFactoryParams({
				animationsApi,
				sdkInstancesCache,
				componentSdkState,
				platformUtils,
				viewerAPI,
				modelsApi,
				createSdkHandlers,
				getCompRefById,
				logger,
				createSetProps,
				registerEventFactory,
				platformEnvData,
				bootstrapData
			})
			const wixSelector = WixSelector({
				bootstrapData,
				modelsApi,
				getSdkFactoryParams,
				controllerEventsFactory,
				sdkInstancesCache,
				componentSdksManager,
				logger
			})
			const reporter = {
				logSdkError,
				logSdkWarning
			}
			const controllersExports: ControllersExports = {}

			const AppControllerSdkLoader = async () => {
				const { AppControllerSdk } = await import('./componentsSDK/AppController' /* webpackChunkName: "AppController.corvid" */)
				return AppControllerSdk({ controllersExports, modelsApi, controllerEventsFactory })
			}

			const AppWidgetSdkLoader = async () => {
				const { AppControllerWithChildrenSdk } = await import('./componentsSDK/AppController' /* webpackChunkName: "AppController.corvid" */)
				return AppControllerWithChildrenSdk({ controllersExports, modelsApi, controllerEventsFactory })
			}

			const RepeaterSdkLoader = async () => {
				const { RepeaterSdk } = await import('./componentsSDK/repeaters' /* webpackChunkName: "Repeater.corvid" */)
				return RepeaterSdk({
					modelsApi,
					viewerAPI,
					wixSelector,
					reporter,
					sdkInstancesCache,
					componentSdkState
				})
			}

			const DocumentSdkLoader = async () => Promise.resolve(DocumentSdkFactory({ modelsApi, wixSelector }))

			const coreSdks: CoreSdkLoaders = {
				AppController: AppControllerSdkLoader,
				AppWidget: AppWidgetSdkLoader,
				TPAWidget: AppControllerSdkLoader,
				TPASection: AppControllerSdkLoader,
				TPAMultiSection: AppControllerSdkLoader,
				TPAGluedWidget: AppControllerSdkLoader,
				tpaWidgetNative: AppControllerSdkLoader,
				Repeater: RepeaterSdkLoader,
				Document: DocumentSdkLoader
			}
			componentSdksManager.fetchComponentsSdks(coreSdks)
			const staticEventsManager = CreateStaticEventsManager({ modelsApi, controllerEventsFactory, wixSelector, logger, componentSdksManager })
			const wixCodeViewerAppUtils = WixCodeViewerAppUtils({ bootstrapData, staticEventsManager })
			const wixCodeApiFactory = createWixCodeApiFactory({
				bootstrapData,
				wixCodeViewerAppUtils,
				modelsApi,
				platformUtils,
				createSdkHandlers,
				platformEnvData,
				logger
			})

			const createPlatformApiForApp = createPlatformApi(bootstrapData, platformUtils, handlers)

			const { startApplications } = Applications({
				appsPublicApiManager,
				platformUtils,
				clientSpecMapApi,
				appsUrlApi,
				modelsApi,
				bootstrapData,
				importScripts,
				moduleLoader,
				wixCodeViewerAppUtils,
				wixSelector,
				logger,
				wixCodeApiFactory,
				createSetPropsForOOI,
				waitForUpdatePropsPromises,
				controllersExports,
				createPlatformApiForApp,
				bsiManager
			})
			logger.interactionEnded('initialisation')

			await logger.runAsyncAndReport('startApplications', startApplications)
			// calling it here because we need to run all the applications, register the controllers APIs, run and finish all PageReady/OnReady, before executing any static events handlers.
			// some handlers may depends on the apis being registered and onReady been called,
			staticEventsManager.triggerStaticEventsHandlers() // TODO do we need to run this is SSR?
		}
	}
}
