import { AppParams, DynamicRouteData, ViewerAppSpecData } from '@wix/thunderbolt-symbols'
import { WixCodeAppDefId } from '../constants'
import { RouterConfig } from '@wix/thunderbolt-ssr-api'

export function createAppParams({
	appSpecData,
	wixCodeViewerAppUtils,
	dynamicRouteData,
	routerConfigMap,
	appInstance,
	baseUrls,
	viewerScriptUrl
}: {
	appSpecData: ViewerAppSpecData
	wixCodeViewerAppUtils: any
	dynamicRouteData?: DynamicRouteData
	routerConfigMap: Array<RouterConfig> | null
	appInstance: string
	baseUrls: Record<string, string> | null | undefined
	viewerScriptUrl: string
}): AppParams {
	const createSpecificAppDataByApp: { [appDefId: string]: (appData: ViewerAppSpecData) => any } = {
		[WixCodeAppDefId]: wixCodeViewerAppUtils.createWixCodeAppData
	}

	return {
		appInstanceId: appSpecData.appDefinitionId,
		appDefinitionId: appSpecData.appDefinitionId,
		appName: appSpecData.appDefinitionName || appSpecData.type || appSpecData.appDefinitionId,
		instanceId: appSpecData.instanceId,
		instance: appInstance,
		url: viewerScriptUrl,
		baseUrls,
		appData: createSpecificAppDataByApp[appSpecData.appDefinitionId] ? createSpecificAppDataByApp[appSpecData.appDefinitionId](appSpecData) : null,
		appRouters: routerConfigMap,
		routerReturnedData: dynamicRouteData?.pageData // TODO deprecate this in favor of wixWindow.getRouterData()
	}
}
