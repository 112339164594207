import _ from 'lodash'
import Raven from 'raven-js'
import { Interaction, PlatformLogger, PlatformEnvData, BreadCrumbOption, ViewerAppsUrls } from '@wix/thunderbolt-symbols'
import { createFedopsLogger, extractFileNameFromErrorStack, extractFingerprints, getEnvironment } from '@wix/thunderbolt-commons'
import { platformBiLoggerFactory } from './bi/biLoggerFactory'
import { WixCodeAppDefId, WixCodeSentryDsn } from './constants'

function getSentryDsn(appsUrlData: ViewerAppsUrls, appDefinitionId: string, widgetId?: string): string | null {
	if (appDefinitionId === WixCodeAppDefId) {
		return WixCodeSentryDsn
	}

	// https://sentry.wixpress.com/sentry/platform-apps/
	const platformAppsDsn = 'https://76e577208263430cb7ab8e220bd84349@sentry.wixpress.com/806'

	const appDsn = _.get(appsUrlData, [appDefinitionId, 'errorReportingUrl']) || platformAppsDsn
	if (widgetId) {
		// Use app dsn as default when looking for component dsn.
		return _.get(appsUrlData, [appDefinitionId, 'widgets', widgetId, 'errorReportingUrl']) || appDsn
	}

	return appDsn
}

export const platformLoggerCreator = ({ biData, appsUrlData, url, isSSR }: { biData: PlatformEnvData['bi']; appsUrlData: ViewerAppsUrls; url: string; isSSR: boolean }): PlatformLogger => {
	let sessionErrorLimit = 50
	const biLoggerFactory = platformBiLoggerFactory().createBiLoggerFactoryForFedops(biData)
	const fedopsLogger = createFedopsLogger({ biLoggerFactory, phasesConfig: 'SEND_START_AND_FINISH' })
	// Using "new Client()" to avoid altering the global raven. See raven-js/src/singleton.js
	// @ts-ignore
	const platformRaven = new Raven.Client()

	platformRaven.config('https://e0ad700df5e446b5bfe61965b613e52d@sentry.wixpress.com/715', {
		tags: { platform: true, url, isSSR, isCached: biData.isCached, isFirstPage: biData.pageData.pageNumber === 1 },
		extra: biData,
		environment: getEnvironment(biData.fleetConfig.code),
		release: biData.viewerVersion
	})
	platformRaven.setDataCallback((event: any, originalCallback = _.identity) => {
		event.fingerprint = event.fingerprint || extractFingerprints(event.exception)
		fedopsLogger.interactionStarted('platform_error', { customParams: { errorMessage: event.message } })
		if (sessionErrorLimit) {
			sessionErrorLimit--
			return originalCallback(event)
		}
		return null
	})
	const captureBreadcrumb = (options: BreadCrumbOption) => platformRaven.captureBreadcrumb(options)

	const captureError = (
		error: Error,
		{ tags, extra, groupErrorsBy = 'tags', warning = false }: { tags: { [_key: string]: string | boolean }; extra?: { [_key: string]: any }; groupErrorsBy?: 'tags' | 'values'; warning?: boolean }
	) => {
		const fingerprints = []
		for (const key in tags) {
			if (tags.hasOwnProperty(key)) {
				if (groupErrorsBy === 'tags') {
					fingerprints.push(key)
				} else if (groupErrorsBy === 'values') {
					fingerprints.push(tags[key])
				}
			}
		}
		const fileName = error.stack ? extractFileNameFromErrorStack(error.stack) : 'unknownFile'

		if (warning) {
			console.warn(error)
		} else {
			console.error(error)
		}
		if (sessionErrorLimit) {
			platformRaven.captureException(error, {
				tags,
				extra,
				fingerprints: [error.message, fileName, ...fingerprints]
			})
		}
	}

	const reportAsyncWithCustomKey = async <T>(methodName: string, key: string, asyncMethod: () => Promise<T>): Promise<T> => {
		try {
			// @ts-ignore @shahaf fedops logger does not have a 'customParam' prop, it's 'customParams' and expects an object
			fedopsLogger.interactionStarted(`platform_${methodName}`, { customParam: key })
			const fnResult = await asyncMethod()
			// @ts-ignore @shahaf fedops logger does not have a 'customParam' prop, it's 'customParams' and expects an object
			fedopsLogger.interactionEnded(`platform_${methodName}`, { customParam: key })
			return fnResult
		} catch (e) {
			captureError(e, { tags: { methodName } })
			throw e
		}
	}

	const runAndReport = <T>(methodName: string, method: () => T): T => {
		try {
			fedopsLogger.interactionStarted(methodName)
			const result = method()
			fedopsLogger.interactionEnded(methodName)
			return result
		} catch (e) {
			captureError(e, { tags: { methodName } })
			throw e
		}
	}

	const runAsyncAndReport = async <T>(methodName: string, asyncMethod: () => Promise<T> | T): Promise<T> => {
		try {
			fedopsLogger.interactionStarted(`platform_${methodName}`)
			const result = await asyncMethod()
			fedopsLogger.interactionEnded(`platform_${methodName}`)
			return result
		} catch (e) {
			captureError(e, { tags: { methodName } })
			throw e
		}
	}

	const withReportingAndErrorHandling: PlatformLogger['withReportingAndErrorHandling'] = async (phase, asyncMethod, params) => {
		try {
			const appIdentifier = { appId: params.appDefinitionId, widgetId: params.controllerType }
			fedopsLogger.appLoadingPhaseStart(phase, appIdentifier)
			const result = await asyncMethod()
			fedopsLogger.appLoadingPhaseFinish(phase, appIdentifier)
			return result
		} catch (e) {
			const error = _.isError(e) ? e : new Error(e)
			reportError(error, params)
			return Promise.resolve(null)
		}
	}

	const withReportingAndErrorHandlingSync: PlatformLogger['withReportingAndErrorHandlingSync'] = (phase, method, params) => {
		try {
			const appIdentifier = { appId: params.appDefinitionId, widgetId: params.controllerType }
			fedopsLogger.appLoadingPhaseStart(phase, appIdentifier)
			const result = method()
			fedopsLogger.appLoadingPhaseFinish(phase, appIdentifier)
			return result
		} catch (e) {
			const error = _.isError(e) ? e : new Error(e)
			reportError(error, params)
			return null
		}
	}

	const interactionStarted = (interaction: Interaction) => fedopsLogger.interactionStarted(`platform_${interaction}`)
	const interactionEnded = (interaction: Interaction) => fedopsLogger.interactionEnded(`platform_${interaction}`)
	const reportAppPhasesNetworkAnalysis = (appId: string) => fedopsLogger.reportAppPhasesNetworkAnalysis({ appId })
	const reportError = (e: Error, params: { appDefinitionId: string; controllerType?: string }) => {
		console.error(e)

		const dsn = getSentryDsn(appsUrlData, params.appDefinitionId, params.controllerType)
		// Using "new Client()" to avoid altering the global raven. See raven-js/src/singleton.js
		// @ts-ignore
		const reporter = new Raven.Client()
		reporter.config(dsn)
		reporter.setDataCallback((event: any, originalCallback = _.identity) => {
			if (!event.fingerprint) {
				const fingerprints = extractFingerprints(event.exception)
				event.fingerprint = [...fingerprints]
			}
			return originalCallback(event)
		})
		reporter.captureException(e)
	}

	return {
		interactionStarted,
		interactionEnded,
		captureError,
		reportAsyncWithCustomKey,
		runAsyncAndReport,
		runAndReport,
		captureBreadcrumb,
		withReportingAndErrorHandling,
		withReportingAndErrorHandlingSync,
		reportAppPhasesNetworkAnalysis
	}
}
