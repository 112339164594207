import _ from 'lodash'
import { PlatformModel, SiteAssetsResourceType, PlatformLogger, Connections } from '@wix/thunderbolt-symbols'
import { SiteAssetsClientAdapter } from 'thunderbolt-site-assets-client'
import { BootstrapData } from '../types'
import { FeaturesResponse, ModelsAPI } from './types'
import { MasterPageId } from './constants'
import { errorPagesIds } from '@wix/thunderbolt-commons'
import { getAPIsOverModel } from './modelsApi'

export default function({ bootstrapData, logger, siteAssetsClient, handlers }: { bootstrapData: BootstrapData; logger: PlatformLogger; siteAssetsClient: SiteAssetsClientAdapter; handlers: any }) {
	const fetchModel = <T>(resourceType: SiteAssetsResourceType, isMasterPage: boolean): Promise<T> =>
		logger.runAsyncAndReport(`getModel_${resourceType}${isMasterPage ? `_${MasterPageId}` : ''}`, () => {
			const pageCompId = isMasterPage ? MasterPageId : `${bootstrapData.currentPageId}`
			const isErrorPage = !!errorPagesIds[pageCompId]
			const errorPageData = isErrorPage ? { pageCompId: isErrorPage ? 'masterPage' : pageCompId, errorPageId: pageCompId } : {}
			const pageJsonFileNames = bootstrapData.siteAssetsClientInitParams.siteScopeParams.pageJsonFileNames
			const pageJsonFileName = isMasterPage || isErrorPage ? pageJsonFileNames[MasterPageId] : pageJsonFileNames[pageCompId]
			// TODO - handle/catch site-assets client error
			logger.captureBreadcrumb({
				message: 'fetchModel',
				category: 'model',
				data: {
					moduleParams: bootstrapData.siteAssetsClientInitParams.modulesParams[resourceType],
					pageCompId,
					isErrorPage,
					errorPageData,
					pageJsonFileName,
					pageJsonFileNames,
					isMasterPage,
					'bootstrapData-pageJsonFileName': bootstrapData.platformEnvData.router.pageJsonFileName
				}
			})
			return siteAssetsClient.execute(
				{
					moduleParams: bootstrapData.siteAssetsClientInitParams.modulesParams[resourceType],
					pageCompId,
					...errorPageData,
					pageJsonFileName: pageJsonFileName || bootstrapData.platformEnvData.router.pageJsonFileName
				},
				bootstrapData.siteAssetsClientInitParams.fallbackStrategy
			)
		})

	function mergeConnections(masterPageConnections: Connections, pageConnections: Connections) {
		// merge connection arrays
		return _.mergeWith(pageConnections, masterPageConnections, (objValue, srcValue) => (_.isArray(objValue) ? objValue.concat(srcValue) : undefined))
	}

	const getModelFromSiteAssetsResponses = (isMasterPage: boolean, [platformModel, featuresModel]: [PlatformModel, FeaturesResponse]) => {
		const {
			props: pageConfig,
			structure: { components: structureModel }
		} = featuresModel
		const { connections, applications, orderedControllers, onLoadProperties } = platformModel
		const propsModel = pageConfig.render.compProps

		return {
			pageConfig,
			propsModel,
			structureModel,
			rawPageStructure: isMasterPage ? {} : structureModel,
			rawMasterPageStructure: isMasterPage ? structureModel : {},
			platformModel: {
				connections,
				applications,
				orderedControllers,
				sdkData: platformModel.sdkData,
				staticEvents: platformModel.staticEvents,
				compIdConnections: platformModel.compIdConnections,
				containersChildrenIds: platformModel.containersChildrenIds,
				compIdToRepeaterId: platformModel.compIdToRepeaterId,
				onLoadProperties
			}
		}
	}

	const fetchPageModel = (pageType: 'masterPage' | 'page') => {
		const isMasterPage = pageType === 'masterPage'
		return Promise.all([fetchModel<PlatformModel>('platform', isMasterPage), fetchModel<FeaturesResponse>('features', isMasterPage)]).then((result) =>
			getModelFromSiteAssetsResponses(isMasterPage, result)
		)
	}

	const getRawModel = async () => {
		const pageModelPromise = fetchPageModel('page')
		if ((bootstrapData.isResponsive && bootstrapData.experiments['specs.thunderbolt.shouldNotFetchPlatformMasterPageResponsive']) || bootstrapData.platformEnvData.bi.pageData.isLightbox) {
			return pageModelPromise
		}

		const masterPageModelPromise = fetchPageModel('masterPage')
		const [pageModel, masterPageModel] = await Promise.all([pageModelPromise, masterPageModelPromise])

		const applications = _.merge({}, masterPageModel.platformModel.applications, pageModel.platformModel.applications)
		const pageConfig = _.merge({}, masterPageModel.pageConfig, pageModel.pageConfig)
		const connections = mergeConnections(masterPageModel.platformModel.connections, pageModel.platformModel.connections)
		const onLoadProperties = _.merge({}, masterPageModel.platformModel.onLoadProperties, pageModel.platformModel.onLoadProperties)
		const structureModel = _.assign({}, masterPageModel.structureModel, pageModel.structureModel)
		const sdkData = _.assign({}, masterPageModel.platformModel.sdkData, pageModel.platformModel.sdkData)
		const staticEvents = _.concat(masterPageModel.platformModel.staticEvents, pageModel.platformModel.staticEvents)
		const compIdConnections = _.assign({}, masterPageModel.platformModel.compIdConnections, pageModel.platformModel.compIdConnections)
		const containersChildrenIds = _.assign({}, masterPageModel.platformModel.containersChildrenIds, pageModel.platformModel.containersChildrenIds)
		const compIdToRepeaterId = _.assign({}, masterPageModel.platformModel.compIdToRepeaterId, pageModel.platformModel.compIdToRepeaterId)
		const orderedControllers = masterPageModel.platformModel.orderedControllers.concat(pageModel.platformModel.orderedControllers)
		const propsModel = pageConfig.render.compProps
		return {
			pageConfig,
			propsModel,
			structureModel,
			rawPageStructure: pageModel.rawPageStructure,
			rawMasterPageStructure: masterPageModel.rawMasterPageStructure,
			platformModel: {
				connections,
				applications,
				orderedControllers,
				sdkData,
				staticEvents,
				compIdConnections,
				containersChildrenIds,
				onLoadProperties,
				compIdToRepeaterId
			}
		}
	}

	async function getModelApi(): Promise<ModelsAPI> {
		const model = await getRawModel()
		model.platformModel.orderedControllers = ['wixCode', ...model.platformModel.orderedControllers]

		const modelsApi = getAPIsOverModel(model, bootstrapData)

		if (!bootstrapData.platformEnvData.window.isSSR) {
			handlers.registerOnPropsChangedHandler(bootstrapData.currentContextId, (changes: { [compId: string]: any }) => {
				_.map(changes, (newProps: any, compId: string) => {
					modelsApi.updateProps(compId, newProps)
				})
			})
		}

		return modelsApi
	}

	return {
		getModelApi
	}
}
